import {ChangeDetectorRef, Directive, ElementRef, inject, Input, OnChanges} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {NgChanges} from '@shared/shared-module/utils/simple-changes-typed';
import {TranslationParams, TranslationString} from '@shared/shared-module/utils/translation.utils';
import {tap} from 'rxjs';

/**
 * This directive uses the translation service directly and
 * makes sure that the input given is always a valid {@see TranslationString}.
 * Invalid strings given will lead to a compilation error
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[safeTranslate]',
  standalone: true
})
export class SafeTranslateDirective implements OnChanges {
  @Input() safeTranslate: TranslationString = '';
  @Input() safeTranslateParams: TranslationParams = [];

  private translateService = inject(TranslateService);
  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.translateService.onLangChange
      .pipe(
        tap(() => {
          this.updateText();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  ngOnChanges(changes: NgChanges<SafeTranslateDirective>) {
    if (changes.safeTranslate || changes.safeTranslateParams) {
      this.updateText();
    }
  }

  private updateText(): void {
    this.elementRef.nativeElement.innerText = this.translateService.instant(
      this.safeTranslate,
      this.safeTranslateParams
    );
  }
}
